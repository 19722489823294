(function () {
  'use strict';

  class DecoratedVideoPlayerService {
    constructor(safetyService, $document, $rootScope) {
      this.safetyService = safetyService;
      this.document = $document[0];
      this.rootScope = $rootScope;
      this.addCloseListener();
    }

    addCloseListener() {
      this.rootScope.$on('closeDecoratedVideoPlayer', () => {
        this.safetyService.unmountReactComponent('decoratedVideoPlayerModalContainer');
      });
    }

    loadDecoratedVideoPlayer(props, isSeparatedVideoScreen = false) {
      const componentId = 'decoratedVideoPlayerModalContainer';

      if (this.document.getElementById(componentId)) {
        this.safetyService.unmountReactComponent(componentId);
      }

      if (!isSeparatedVideoScreen) {
        props.onCloseDecoratedVideoPlayer = () => {
          this.rootScope.$apply(() => this.rootScope.$emit('closeDecoratedVideoPlayer'));
        };
      }

      this.safetyService.loadReactComponent('safetyApp', './VideoPlayerModal', componentId, props)
        .catch(error => console.error('Error loading Decorated Video Player:', error));
    }
  }

  DecoratedVideoPlayerService.$inject = ['safetyService', '$document', '$rootScope'];

  angular
    .module('videoPlayer')
    .service('DecoratedVideoPlayerService', DecoratedVideoPlayerService);
})();
